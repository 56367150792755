.cl_footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 99%;
    padding-left: 1%;
    padding-right: 0%;
    padding-bottom: 10px;
    background-color: none;
    /*z-index: 11;*/
}
.cl_loginbar
{
    width: 100vw;
    height: 30px;
}
.cl_localesdropdown {
    background: none;
    border: #ffffff 1px solid;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 10px;
    /*margin-left: 5px;*/
    float: right;
    color: #ffffff;
    border:none;
    font-weight: bold;
    outline: none;
    margin-top: 1px;
}
.cl_leftlocator {
    float: left;
    color: #ffffff;
    margin-left: 2%;
    text-align: center;


}
.cl_table1
{
    width:100%;
    font-size: 15px;
}

.cl_td1
{
    width:20%;
    text-align: center;
}

.cl_td2
{
    width:60%;
   text-align: center;
}
.cl_rightlocator {
    float: right;
    color: #ffffff;
    text-align: center;

}

.cl_centerlocator {
    float: center;
    color: #ffffff;
    width: 100%;
    text-align: center;
}

.cl_footerlink1 {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    margin-right: 1%;
}
.pagesdropdown {
    background: none;
    border: #ffffff 1px solid;
    border-radius: 5px;
    margin-left: 5px;

}

@media only screen and (max-width: 850px) {
    .cl_table1
    {
    width:100%;
    font-size: 13px;
    }
     .wrapper
    {
    min-height: 800px;
    }
  .cl_footerlink1 {
    display: none;
  }
    
  .cl_rightlocator {
    width: 100%;
    text-align: center;
    float: right;
  }

  .cl_centerlocator {
    width: 100%;
    text-align: center;
    float: center;
  }
  .cl_footer {
    position: unset;
  }

}